import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import * as AOS from 'aos';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "dexam";
  constructor(private router: Router,private route: ActivatedRoute) {
    this.loadScripts();
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // init AOS Animation
    AOS.init({
      duration: 1200,
      once: false,
    });
  }
  // onActivate(event) {
  //   window.scroll(0, 0);
  // }
  // Method to dynamically load JavaScript 
  loadScripts() {

    // This array contains all the files/CDNs 
    const dynamicScripts = [];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
