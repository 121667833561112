import {LandingLayoutComponent} from "./shared/components/layouts/landing-layout/landing-layout.component";
import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import APP_DATA from './constant/constant';
const routes: Routes = [
  {
    path: "",
    // comment out this 2 line to make any landing demo as your root .
    redirectTo: APP_DATA.source + '/' + APP_DATA.home,
    pathMatch: "full",

    /** 
      uncomment this to make root url as one of landing page .
      and open landing-routing.module.ts to select which demo version 
      you want as your root

      like : 
      in landing-routing.module.ts
    {
    path: "",
    component: LandingV2Component
  },
    
    **/

    /**  component: LandingLayoutComponent,
     children: [
       {
         path: '',
         loadChildren: './views/landing/landing.module#LandingModule'
      }
     ]
     **/
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
      },
    ],
  },
  {
    path: "",
    component: LandingLayoutComponent,
    children: [
      {
        path: APP_DATA.source,
        loadChildren: () =>
          import("./views/landing/landing.module").then((m) => m.LandingModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
